import { Injectable } from '@angular/core';
import { RestApiService } from '../services/rest-api.service';
import { Observable, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { AppSettings } from '../app.settings';
import { CommonBindingDataService } from './common-binding-data.service';
import { UiService } from './ui.service';
import { SidebarService } from './sidebar.service';
import { ErrorBindingService } from './error-binding.service';
declare var Stripe: any;

@Injectable()
export class UserCommonService {
  redirectUrl = '/';
  isMetadataLoaded = false;
  isCompanyRole = false;
  stripElements: any;
  stripe: any;
  userEmail: string;
  otpId: any;
  loginErrors = [{
    email: false,
    password: false,
  }];
  isMeCallSuccess = false;
  isUserMetadataPresent = false;
  isRolesAndGenerateSidebar = false;

  metaDataUpdate = new Subject();

  constructor(
    private restApiService: RestApiService,
    private uiService: UiService,
    private commonBindingDataService: CommonBindingDataService,
    private sidebarService: SidebarService,
    private errorBindingService: ErrorBindingService,
    private storageService: StorageService) {
  }

  setOtpValue(value) {
    this.otpId = value;
  }

  signOut(): Observable<any> {
    return this.restApiService.delete('sign-out', '/secure/signout', 'page-center');
  }

  signIn(data): Observable<any> {
    return this.restApiService.post('sign-in', '/user-signin', data, 'page-center');
  }

  passwordChange(data): Observable<any> {
    return this.restApiService.put('', '/secure/users/profile/password', data, 'page-center');
  }

  setPassword(data): Observable<any> {
    return this.restApiService.post('public', '/users/set-password', data, 'page-center');
  }

  getUserProfile(): Observable<any> {
    return this.restApiService.get('public', '/secure/users/profile', 'page-center');
  }

  updateUserProfile(data): Observable<any> {
    return this.restApiService.put('', '/secure/users/user/profile', data, 'page-center');
  }

  forgotPassword(data): Observable<any> {
    this.userEmail = data.email;
    return this.restApiService.post('public', '/users/forgot-password', data, 'page-center');
  }

  validateOtp(data): Observable<any> {
    return this.restApiService.post('public', '/users/verify-otp', data, 'page-center');
  }

  getServiceAreaId(): Observable<any> {
    return this.restApiService.get('', '/secure/tenants/servicearea', 'page-center');
  }

  getUserMetaData(): Observable<any> {
    return this.restApiService.get('', '/secure/user-metadata', 'page-center');
  }

  getUserMetaDataInsecure(): Observable<any> {
    return this.restApiService.get('meta-data', '/admin/metadata', 'page-center');
  }

  getTrackMyLocationDetails(bookingId): Observable<any> {
    return this.restApiService.get('public', '/track-my-location/' + bookingId, 'page-center');
  }

  getImage(fileId): Observable<any> {
    return this.restApiService.base64Image('public', '/file/' + fileId, 'page-center');
  }

  getTrackMyLiveLocation(bookingId): Observable<any> {
    return this.restApiService.get('public', '/track-my-location/live/' + bookingId, 'page-center');
  }

  userMe(): Observable<any> {
    return this.restApiService.get('', '/secure/users/me', 'page-center');
  }

  sesionRefresh(): Observable<any> {
    return this.restApiService.get('', '/secure/users/refresh', 'page-center');
  }

  getdocumentDownload(documentId: string) {
    return this.restApiService.image('', `${AppSettings.BASE_URL}/${AppSettings.TENANT}/secure/file/${documentId}`, documentId);
  }

  getAdminUserMetaData() {
    this.getUserMetaDataInsecure().subscribe(result => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${result.googleBrowserAPIKey}&libraries=visualization,places`;
      document.getElementsByTagName('head')[0].appendChild(script);
      setTimeout(() => {
        this.isMetadataLoaded = true;
      }, 500);
    }, (error) => {
    });
  }

  getUserMasterMetaData() {
    return new Promise((resolve, reject) => {
      this.getUserMetaData().subscribe(result => {
        this.isUserMetadataPresent = true;
        this.storageService.setItem(AppSettings.TIME_SETTINGS, result.timeSettingsList);
        this.storageService.setItem(AppSettings.TENANT_CONFIG, result.tenantConfigKeys);
        const data = result.tenantConfigKeys.filter(element => element.configKey === 'export_max_date_range')
        if (data.length > 0) {
          AppSettings.MAX_EXPORT_DAYS = data[0].configValue;
        }
        this.storageService.setItem(AppSettings.TENANT_INFO, result.tenantInfo);
        this.storageService.setItem(AppSettings.MEMBER_REQUIREMENT, result.memberRequirementList);
        this.storageService.setItem(AppSettings.WEEK_DAY, result.dayOfWeekList);
        this.setServiceTypes(result.tenantServiceTypeList);
        this.setServiceArea(result.tenantServiceAreaList);
        this.setBookingStatus(result.tourStatusConstants);
        this.setBookingSubStatus(result.tourSubStatusConstants);
        this.setEmergencyStatusList(result.emergencyStatusList);
        this.setAppointmentType(result.appointmentTypeList);
        this.setColorsList(result.colorsList);
        this.setYearsList(result.yearsList);
        this.setMakeDetailsList(result.makeDetailsList);
        this.setDriverStatusFilter(result.driverStatusFilter);
        this.isMetadataLoaded = true;
        resolve(true);
        this.metaDataUpdate.next(new Date().getTime());
      }, (error) => {
        this.errorBindingService.onAPIValidationError(error, this.loginErrors);
        resolve(true);
      });
    });
  }

  setServiceTypes(serviceTypes) {
    const serviceTypeArray: any[] = [
      { label: this.commonBindingDataService.getLabel('please_select'), value: '' },
    ];
    for (let type = 0; type < serviceTypes.length; type++) {
      serviceTypeArray.push({ label: serviceTypes[type].serviceType, value: serviceTypes[type].id });
    }
    this.storageService.setItem(AppSettings.SERVICE_TYPE, serviceTypeArray);
  }

  setServiceArea(serviceAreas) {
    const serviceAreaArray: any[] = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '' }];
    const serviceAreaAliasArray: any[] = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '' }];
    const serviceAreaMapArray: any[] = [{ label: this.commonBindingDataService.getLabel('label_all_cities'), value: AppSettings.TENANT }];
    const multiSelectServiceArea: any[] = [];

    for (let area = 0; area < serviceAreas.length; area++) {
      serviceAreaArray.push({ label: serviceAreas[area].cityName, value: serviceAreas[area].id });
      serviceAreaMapArray.push({ label: serviceAreas[area].cityName, value: serviceAreas[area].id });
      serviceAreaAliasArray.push({ label: serviceAreas[area].cityAliasName, value: serviceAreas[area].id });
      multiSelectServiceArea.push({ label: serviceAreas[area].cityAliasName, value: serviceAreas[area].id });
    }
    this.storageService.setItem(AppSettings.SERVICE_AREA, serviceAreaArray);
    this.storageService.setItem(AppSettings.SERVICE_AREA_ALIAS, serviceAreaAliasArray);
    this.storageService.setItem(AppSettings.SERVICE_CITIES_MAP, serviceAreaMapArray);
    this.storageService.setItem(AppSettings.MUTLTI_SELECT_SERVICE_CITIES, multiSelectServiceArea);
  }

  setBookingStatus(bookingStatus) {
    const bookingStatusArray = [{ label: this.commonBindingDataService.getLabel('label_all'), value: '' }];
    for (let status = 0; status < bookingStatus.length; status++) {
      bookingStatusArray.push({ label: bookingStatus[status].displayValue, value: bookingStatus[status].tourStatusCode });
    }
    this.storageService.setItem('BOOKING_STATUS', bookingStatusArray);
  }

  setBookingSubStatus(bookingStatus) {
    const bookingStatusArray = [];
    Object.keys(bookingStatus).forEach(element => {
      bookingStatusArray.push({ value: Number(element), label: bookingStatus[element] });
    })
    this.storageService.setItem('BOOKING_SUB_STATUS', bookingStatusArray);
  }

  setEmergencyStatusList(emergencyStatus) {
    const emergencyStatusList = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '' }];
    for (let i = 0; i < emergencyStatus.length; i++) {
      emergencyStatusList.push({ label: emergencyStatus[i].value, value: emergencyStatus[i].id });
    }
    this.storageService.setItem(AppSettings.EMERGENCY_STATUS_LIST, emergencyStatusList);
  }

  setAppointmentType(appointmentStatus) {
    const appointmentArray = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '' }];
    for (let status = 0; status < appointmentStatus.length; status++) {
      appointmentArray.push({ label: appointmentStatus[status].appointmentType, value: appointmentStatus[status].appointmentTypeId });
    }
    this.storageService.setItem(AppSettings.APPOINTMENT_TYPE_LIST, appointmentArray);
  }


  setColorsList(colors) {
    const colorsList = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '' }];
    for (let i = 0; i < colors.length; i++) {
      colorsList.push({ label: colors[i].color, value: colors[i].id });
    }
    this.storageService.setItem(AppSettings.COLORS_LIST, colorsList);
  }

  setYearsList(years) {
    const yearsList = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '' }];
    for (let i = 0; i < years.length; i++) {
      yearsList.push({ label: years[i].year, value: years[i].id });
    }
    this.storageService.setItem(AppSettings.YEARS_LIST, yearsList);
  }

  setMakeDetailsList(makeDetails) {
    const makeDetailsList = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '' }];
    for (let i = 0; i < makeDetails.length; i++) {
      makeDetailsList.push({ label: makeDetails[i].make, value: makeDetails[i].id });
    }
    this.storageService.setItem(AppSettings.MAKE_DETAILS_LIST, makeDetailsList);
  }

  setDriverStatusFilter(status) {
    const statusList = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '' }];
    for (let i = 0; i < status.length; i++) {
      statusList.push({ label: status[i].value, value: status[i].id });
    }
    this.storageService.setItem(AppSettings.DRIVER_STATUS_FILTER, statusList);
  }

  setRolesAndGenerateSidebar() {
    const userDetails: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userDetails.roles[0].roleCode !== undefined && userDetails.roles[0].roleCode !== null) {
      this.isRolesAndGenerateSidebar = true;
      AppSettings.LOGGED_IN_ROLE = userDetails.roles[0].roleCode;
      this.sidebarService.generateLeftMenuBar();
      this.uiService.profileUpdated();
    } else {
      this.storageService.removeAll();
      const error = {
        general: [{
          message: this.commonBindingDataService.getLabel('label_invalid_role')
        }]
      };
      this.errorBindingService.onAPIValidationError(error, this.loginErrors);
    }
  }

  getGoogleMapAutoComplete(input, location?): Observable<any> {
    return this.restApiService.get('', `/secure/google-map/autocomplete?input=${input}&location=${location}`, 'page-center');
  }

  getGoogleMapLatLng(placeId): Observable<any> {
    return this.restApiService.get('', `/secure/google-map/geocode?placeId=${placeId}`, 'page-center');
  }

  getGoogleMapPlace(latLng): Observable<any> {
    return this.restApiService.get('', `/secure/google-map/reverse-geocode?latLng=${latLng}`, 'page-center');
  }

  getGoogleMapDirection(origin, destination): Observable<any> {
    return this.restApiService.get('', `/secure/google-map/directions?origin=${origin}&destination=${destination}`, 'page-center');
  } 

  sendOTP(data): Observable<any> {
    return this.restApiService.post('', '/sign-in/send-otp', data, 'page-center');
  }

  verifyOTP(data): Observable<any> {
    return this.restApiService.post('', '/sign-in/passenger/verify-otp', data, 'page-center');
  }

  deleteAccount(data, authorizationKey): Observable<any> {
    return
    // return this.restApiService.postWithCustomHeader('', '/secure/verify-otp/delete/useraccount', authorizationKey, data, 'page-center');
  }
}

