import { Component, OnInit } from '@angular/core';
import { UserCommonService } from '../../modules/shared/services/user-common.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from 'app/modules/shared/app.settings';
import { UiService } from 'app/modules/shared/services/ui.service';


@Component({
  selector: 'app-delete-user-account',
  templateUrl: './delete-user-account.component.html',
  styleUrls: ['./delete-user-account.component.scss']
})
export class DeleteUserAccountComponent implements OnInit {

  phoneOrEmail:any = '';
  otp:any = '';
  userData:any = '';
  confirmOTP: any = '';
  isConfirmPopDisplay = false;
  isSendOTP = false;
  authorizationKey = '';
  userDetail:any; 
  isNumber = false;
  timeLeft = 15;
  confirmTimeLeft = 15;
  interval;
  ConfirmOTPInterval;
  isRedirected = false;
  deleteForm = new FormGroup({
    phoneOrEmail: new FormControl(),
    otp: new FormControl(),
    numCountryCode: new FormControl()
  });

  constructor(
    private formBuilder: FormBuilder,
    private uiServices: UiService,
    private userCommonService: UserCommonService
  ) { }

  ngOnInit() { 
    this.deleteForm = this.formBuilder.group({
      phoneOrEmail: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_OR_PHONE)]],
      numCountryCode: ['254'],
      otp: ['', [Validators.required]]
    });

  } 

  checkInputValue() {
    if (isNaN(this.deleteForm.controls.phoneOrEmail.value)) {
      this.isNumber = false;
    } else {
      this.isNumber = true;
    }
  }
  
  cancel() {
    this.confirmOTP = '';
    this.isSendOTP = false;
    this.deleteForm.reset();
  }

  cancelFromDialog() {
    this.confirmOTP = '';
    this.isConfirmPopDisplay = false;
    }

  deleteMyAccount() {
    let data = {
      email: this.deleteForm.controls.phoneOrEmail.value,
      phoneNum: '',
      otp: this.deleteForm.controls.otp.value,
      numCountryCode: '',
      deviceDetails: {
        deviceId:null,
        deviceType:null,
        os:null,
        osVersion:null,
        appVersion:null,
        notificationToken:null
      }
    }
    if(this.isNumber) {
       data = {
        email: '',
        phoneNum: this.deleteForm.controls.phoneOrEmail.value,
        otp: this.deleteForm.controls.otp.value,
        numCountryCode: this.deleteForm.controls.numCountryCode.value,
        deviceDetails: {
          deviceId:null,
          deviceType:null,
          os:null,
          osVersion:null,
          appVersion:null,
          notificationToken:null
        }
      }
    }
    

  this.userCommonService.verifyOTP(data).subscribe((result)=> {
    if (result.sessionToken) {
      this.authorizationKey = result.sessionToken;
      this.userDetail = result.user;
      this.sendOTPForDeleteConfirmation();
    }
  },
(error)=> {
  this.uiServices.showMessage(AppSettings.SEVERITY.ERROR, error.general[0].message, AppSettings.SUMMERY.ERROR);
});
}

  deleteAccountPermanently () {
    const data = {
      userId: this.userDetail.id,
      otp: this.confirmOTP,
      deviceDetails: {
        deviceId:null,
        deviceType:null,
        os:null,
        osVersion:null,
        appVersion:null,
        notificationToken:null
      }
    }

    this.userCommonService.deleteAccount(data, this.authorizationKey).subscribe(result=> {
    this.uiServices.showMessage(AppSettings.SEVERITY.SUCCESS, result.general[0].message, AppSettings.SUMMERY.SUCCESS);
    this.isConfirmPopDisplay = false;
    this.deleteForm.reset();
    this.confirmOTP = '';
    this.isSendOTP = false;
    }, error => {
      this.uiServices.showMessage(AppSettings.SEVERITY.ERROR, error.general[0].message, AppSettings.SUMMERY.ERROR);
    });
  }

  sendOTP() {
    let data: any = 
    {
      email: this.deleteForm.controls.phoneOrEmail.value,
      type: 'login'
  }
    if(this.isNumber) {
      data = 
    {
      phoneNum: this.deleteForm.controls.phoneOrEmail.value,
      numCountryCode: this.deleteForm.controls.numCountryCode.value,
      type: 'login'
  }
    } 
    this.userCommonService.sendOTP(data).subscribe((result)=> {
      if (result.Status === 'SUCCESS') {
        this.isSendOTP = true;
        this.timeLeft = 15;
        this.startTimer();
      }
    },
  (error)=> {
    this.uiServices.showMessage(AppSettings.SEVERITY.ERROR, error.general[0].message, AppSettings.SUMMERY.ERROR);
  });

  }

  sendOTPForDeleteConfirmation() {
    let data:any = 
      {
        email: this.deleteForm.controls.phoneOrEmail.value,
        type: "delete_account"
    }

    if(this.isNumber) {
       data = 
      {
        phoneNum: this.deleteForm.controls.phoneOrEmail.value,
        numCountryCode: this.deleteForm.controls.numCountryCode.value,
        type: "delete_account"
    }
    }
    this.userCommonService.sendOTP(data).subscribe((result)=> {
      if (result.Status === 'SUCCESS') {
        this.isConfirmPopDisplay = true;
        this.confirmTimeLeft = 15;
        this.confirmOtpStartTimer();
      }
    },
  (error)=> {
    this.uiServices.showMessage(AppSettings.SEVERITY.ERROR, error.general[0].message, AppSettings.SUMMERY.ERROR);
  });
  }
  
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.removeTimer();
      }
    }, 1000);
  }

  removeTimer() {
    clearInterval(this.interval);
  }

  confirmOtpStartTimer() {
    this.ConfirmOTPInterval = setInterval(() => {
      if (this.confirmTimeLeft > 0) {
        this.confirmTimeLeft--;
      } else {
        this.confirmOtpRemoveTimer();
      }
    }, 1000);
  }

  confirmOtpRemoveTimer() {
    clearInterval(this.ConfirmOTPInterval);
  }

  redirectToDeleteAccount() {
    this.isRedirected = true;
  }
}
