import { Injectable, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable()
export class UiService {

  private currentLanguage = 'en_US';
  private _languageChangeEvent = new EventEmitter<string>();
  private _sidebarToggledEvent = new Subject<string>();
  private _LoaderStateChangeEvent = new EventEmitter<string>();
  private _profileUpdateEvent = new Subject<string>();

  constructor(private messageService: MessageService) { }

  changeLanguage(language: string) {
    this.currentLanguage = language;
    this._languageChangeEvent.emit(language);
  }

  get languageChangeEvent(): EventEmitter<string> {
    return this._languageChangeEvent;
  }


  changeLoaderStatus(state: string) {
    this._LoaderStateChangeEvent.emit(state);
  }
  get LoaderStatusChangeEvent(): EventEmitter<string> {
    return this._LoaderStateChangeEvent;
  }

  sidebarToggled() {
    this._sidebarToggledEvent.next();
  }

  get sidebarToggledEvent(): Subject<string> {
    return this._sidebarToggledEvent;
  }

  profileUpdated() {
    this._profileUpdateEvent.next();
  }

  get profileUpdatedEvent(): Subject<string> {
    return this._profileUpdateEvent;
  }

  showMessage(severity, message, summary?) {
    this.messageService.clear();
    if (summary) {
      this.messageService.add({ key: 'commonToast', severity: severity.toLowerCase(), summary: summary, detail: message });
    } else {
      this.messageService.add({ key: 'commonToast', severity: severity.toLowerCase(), summary: severity, detail: message });
    }
  }
}
