// ********************** Google Search API Call From Backend  ***********************

import { Directive, ElementRef, EventEmitter, Output, Input, OnChanges, HostListener, Renderer2 } from '@angular/core';
import { UserCommonService } from '../services/user-common.service';
import { UiService } from '../services/ui.service';
import { AppSettings } from '../app.settings';
declare const google: any;

@Directive({
  selector: '[appSearchLocation]'
})
export class SearchLocationDirective implements OnChanges {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Input() onlyCity = false;
  private _el: HTMLElement;
  private dropdown: HTMLElement;
  private autocompleteResults: any[] = [];

  constructor(
    private el: ElementRef, 
    private userCommonService: UserCommonService, 
    private renderer: Renderer2, 
    private uiService: UiService,
  ) {
    this._el = el.nativeElement;
    this.renderer.setAttribute(this._el, 'autocomplete', 'off');
    this._el.onpaste = (e) => e.preventDefault();
    this.createDropdown();
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    if (input.length % 3 === 0 && input) {
      this.getAutocompleteSuggestions(input);
    } else if (!input) {
      this.clearDropdown();
    }
  }

  // @HostListener('keydown', ['$event'])
  // onKeyDown(event: KeyboardEvent): void {
  //   const input = (event.target as HTMLInputElement).value;
  //   if (event.key === 'Enter') {
  //     this.getAutocompleteSuggestions(input);
  //   }
  // }

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    const isInputFocused = this._el.contains(event.target as Node);
    const isDropdownFocused = this.dropdown.contains(event.target as Node);

    if (!isInputFocused && !isDropdownFocused) {
      this.hideDropdown();
    } else if(isInputFocused){
      this.showDropdown();
    }
  }

  getAutocompleteSuggestions(input: string): void {
    this.userCommonService.getGoogleMapAutoComplete(input).subscribe(
      (response: any) => {
        if (response.status === 'OK' && response.predictions.length) {
          this.autocompleteResults = response.predictions;
          this.populateDropdown();
        } else if(response.status === 'OVER_QUERY_LIMIT' && response.error_message){
          // this.uiService.showMessage(response.error_message, AppSettings.MESSAGE_TYPE.ERROR)
          console.log('response.error_message', response.error_message);
        } else {
          this.clearDropdown();
        }
      },
      (error) => {
        this.clearDropdown();
      }
    );
  }

  hideDropdown(){
    this.renderer.setStyle(this.dropdown, 'display', 'none');
  }

  showDropdown(){
    this.renderer.setStyle(this.dropdown, 'display', 'block');
  }

  createDropdown(): void {
    this.dropdown = this.renderer.createElement('div');
    this.renderer.setStyle(this.dropdown, 'position', 'absolute');
    this.renderer.setStyle(this.dropdown, 'backgroundColor', 'white');
    this.renderer.setStyle(this.dropdown, 'border', '1px solid #ccc');
    this.renderer.setStyle(this.dropdown, 'zIndex', '1000');
    this.renderer.setStyle(this.dropdown, 'maxHeight', '200px');
    this.renderer.setStyle(this.dropdown, 'overflowY', 'auto');
    this.renderer.appendChild(document.body, this.dropdown);
    this.positionDropdown();
  }

  positionDropdown(): void {
    const rect = this._el.getBoundingClientRect();
    this.renderer.setStyle(this.dropdown, 'top', rect.bottom + 'px');
    this.renderer.setStyle(this.dropdown, 'left', rect.left + 'px');
  }

  populateDropdown(): void {  
    const parent = this._el.parentNode;
    const children = parent.children;
    if(children.length > 1){
      parent.removeChild(children[1]);
    }

    const parentElement = this.renderer.parentNode(this._el); 
    this.renderer.setStyle(parentElement, 'position', 'relative');

    const dropdownContainer = this.renderer.createElement('div');
    this.renderer.setStyle(dropdownContainer, 'height', '160px');
    this.renderer.setStyle(dropdownContainer, 'background-color', '#fff');
    this.renderer.setStyle(dropdownContainer, 'overflow-y', 'auto');
    this.renderer.setStyle(dropdownContainer, 'position', 'absolute');
    this.renderer.setStyle(dropdownContainer, 'z-index', '1000');
    this.renderer.setStyle(dropdownContainer, 'border', '1px solid #ccc');
    this.renderer.setStyle(dropdownContainer, 'borderTop', 'none');
    this.renderer.setStyle(dropdownContainer, 'marginTop', '-8px');
  
    this.renderer.insertBefore(this._el.parentNode, dropdownContainer, this._el.nextSibling);
  
    this.autocompleteResults.forEach((result) => {
      const option = this.renderer.createElement('div');
      this.renderer.setStyle(option, 'padding', '8px');
      this.renderer.setStyle(option, 'cursor', 'pointer');
      this.renderer.setStyle(option, 'width', `${this._el.offsetWidth}px`);
      this.renderer.listen(option, 'click', () => this.onOptionSelected(result));
      const text = this.renderer.createText(result.description);
      this.renderer.appendChild(option, text);
      this.renderer.appendChild(dropdownContainer, option);
    });
  
    this.dropdown = dropdownContainer;
  }

  clearDropdown(): void {
    const parent = this._el.parentNode;
    const children = parent.children;
    if(children.length > 1){
      parent.removeChild(children[1]);
    }
  }

  onOptionSelected(option: any): void {

    this.userCommonService.getGoogleMapLatLng(option.place_id).subscribe(response => {
      if(response.status === 'OK'){
        const placeObj = {
          name: option.description,
          address: option.description,
          lat: response.results[0].geometry.location.lat,
          lng: response.results[0].geometry.location.lng,
          id: this._el.id,
          postalCode: this.getZipCode(response.results[0]),
          addressObj: response.results[0].address_components,

        };
        this.invokeEvent(placeObj);
        (this._el as HTMLInputElement).value = option.description;
        this.clearDropdown();
      }
    });
  }

  getZipCode(result): string {
    let zipCode = '';
    for (const component of result.address_components) {
      if (component.types.includes('postal_code')) {
        zipCode = component.short_name;
      }
    }
    return zipCode;
  }

  ngOnChanges() {

  }

  invokeEvent(place: object) {
    this.setAddress.emit(place);
  }
}

// ************************** Google Search API Call From FrontEnd  ***********************************


// Old code 
// import { Directive, ElementRef, EventEmitter, Output, Input, OnChanges } from '@angular/core';
// declare const google: any;
// @Directive({
//   selector: '[appSearchLocation]'
// })
// export class SearchLocationDirective implements OnChanges {
//   @Output() setAddress: EventEmitter<any> = new EventEmitter();
//   @Input() onlyCity: Boolean = false;
//   private _el: HTMLElement;
//   autocomplete: any;
//   constructor(el: ElementRef) {
//     this._el = el.nativeElement;
//     const input = this._el;
//     this.autocomplete = new google.maps.places.Autocomplete(input, {});
//     google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
//       const place = this.autocomplete.getPlace();
//       try {
//         const placeObj = {
//           'name': place.name,
//           'addressObj': place.address_components,
//           'address': input['value'],
//           'lat': place.geometry.location.lat(),
//           'lng': place.geometry.location.lng(),
//           'id': input.id
//         };
//         this.invokeEvent(placeObj);
//       } catch (ex) {
//         console.warn(ex);
//       }

//     });

//   }

//   ngOnChanges() {
//     if (this.onlyCity) {
//       this.autocomplete.setOptions({ types: ['(cities)'] });
//     }
//   }
//   invokeEvent(place: Object) {
//     this.setAddress.emit(place);
//   }
// }
