import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import { Observable } from 'rxjs';
import { StorageService } from './../../shared/services/storage.service';
import { AppSettings } from './../../shared/app.settings';
import { TranslateService } from '@ngx-translate/core';
import { Subject, of } from 'rxjs';

@Injectable()
export class CommonBindingDataService {
  filterData: any = {};
  accesses: any;
  manualBookingData: any = {};
  rideType: any;
  carddetails: any = {};
  public redrawSidebar: Subject<any> = new Subject<any>();

  constructor(private restApiService: RestApiService,
    private storageService: StorageService,
    private translateService: TranslateService
  ) { }

  getServiceType(): Observable<any> {
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      return of(this.storageService.getItem(AppSettings.SERVICE_TYPE));
    } else {
      return this.restApiService.get('', '/secure/service-types?clientType=WEB', 'page-center');
    }
  }

  toGMT(now) {
    return new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).getTime();
  }

  toLocalTime(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000).getTime();
  }

  toLocalDate(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000);
  }

  unitConversionMToKm(m) {
    const results = m / 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionKMToM(km) {
    const results = km * 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMinToMiliseconds(min) {
    const results = ((min * 60) * 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  getBookLaterMinTime(serviceType, subcode) {
    const extraTime = this.getSpecificTimeout(serviceType, subcode);
    return new Date((new Date).getTime() + extraTime);
  }

  unitConversionMilisecondsToMin(mili) {
    const results = ((mili / 60) / 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  addLapseTimeInEndDate(endDate) {
    return endDate + AppSettings.twentyThreeFiftyNineLapse;
  }

  centerLatLng(dropPoints) {
    if (dropPoints.lenght === 0) {
      return 0;
    }
    const latArray = [];
    const lngArray = [];
    for (const points of dropPoints) {
      latArray.push(points.lat);
      lngArray.push(points.lng);
    }
    return this.findCenterDashLatLng(lngArray, latArray);
  }

  findCenterDashLatLng(lngArray, latArray) {
    const x1 = this.findMinMaxValue('min', lngArray);
    const x2 = this.findMinMaxValue('max', lngArray);
    const y1 = this.findMinMaxValue('min', latArray);
    const y2 = this.findMinMaxValue('max', latArray);
    const centerPoly = {
      lat: y1 + ((y2 - y1) / 2),
      lng: x1 + ((x2 - x1) / 2)
    };
    return centerPoly;
  }

  findMinMaxValue(type, data) {
    if (type === 'max') {
      return Math.max.apply(null, data);
    } else {
      return Math.min.apply(null, data);
    }
  }

  getServiceTypeKey(id) {
    let serviceType, serviceTypeId;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.id === id) {
          serviceTypeId = element.serviceType;
        }
      });
    }
    return serviceTypeId;
  }

  getTenantConfigValue(configKey) {
    let tenantConfig: any;
    tenantConfig = this.storageService.getItem(AppSettings.TENANT_CONFIG);
    tenantConfig = tenantConfig === null ? [] : tenantConfig;
    for (let index = 0; index < tenantConfig.length; index++) {
      if (tenantConfig[index].configKey === configKey) {
        return tenantConfig[index].configValue;
      }
    }
    return null;
  }

  getServiceTypeCodeBy(id) {
    let serviceType, code;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.id === id) {
          code = element.code;
        }
      });
    }
    return code;
  }

  getServiceTypeByCode(code) {
    let serviceType;
    const serviceTypeByCode = [];
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.code === code) {
          serviceTypeByCode.push(element);
        }
      });
    }
    return serviceTypeByCode;
  }

  getServiceTypeId(key) {
    let serviceType, serviceTypeId;
    if (this.storageService.getItem(AppSettings.SERVICE_TYPE) !== null) {
      serviceType = this.storageService.getItem(AppSettings.SERVICE_TYPE);
      serviceType.forEach(element => {
        if (element.serviceType === key) {
          serviceTypeId = element.id;
        }
      });
    }
    return serviceTypeId;
  }

  removeGivenServiceTypefromList(key, serviceTypeList?) {
    let serviceTypes;
    if (serviceTypeList) {
      serviceTypes = serviceTypeList;
    } else {
      serviceTypes = this.storageService.getItem(AppSettings.SERVICE_TYPE);
    }
    serviceTypes.forEach((item, index) => {
      if (key === item.subCode) {
        serviceTypes.splice(index, 1);
      }
    });
    return serviceTypes;
  }

  getSpecificTimeout(code, subcode) {
    const timeouts = this.storageService.getItem(AppSettings.TIME_SETTINGS);
    if (timeouts !== null) {
      for (const item of timeouts[code]) {
        if (item.key === subcode) {
          return parseInt(item.value, 10);
        }
      }
    }
  }

  getServiceTypeOnLogin(): Observable<any> {
    return this.restApiService.get('', '/secure/service-types?clientType=WEB', 'page-center');
  }

  getDocumentType(): Observable<any> {
    return this.restApiService.get('', '/secure/driver-document-types', 'page-center');
  }

  getVehicleType(): Observable<any> {
    return this.restApiService.get('', '/secure/vehicle-types', 'page-center');
  }

  getDriver(): Observable<any> {
    return this.restApiService.get('', '/secure/drivers/list/data', 'page-center');
  }

  getVehiclesType(vehicleTypeId, serviceTypeIds): Observable<any> {
    return this.restApiService.get('', '/secure/vehicles/data/search?vehicleTypeIds=' + vehicleTypeId +
      '&serviceTypeIds=' + serviceTypeIds, 'page-center');
  }

  getVehiclesByCompanyAndVehicleType(vehicleTypeId, companyId?: any): Observable<any> {
    if (companyId !== null && companyId !== undefined) {
      return this.restApiService.get('', '/secure/vehicles/data/search?vehicleTypeIds=' + vehicleTypeId
        + '&companyId=' + companyId, 'page-center');
    } else {
      return this.restApiService.get('', '/secure/vehicles/data/search?vehicleTypeIds=' + vehicleTypeId
        + '&companyId=', 'page-center');
    }
  }

  getVehicleDocumentType(): Observable<any> {
    return this.restApiService.get('', '/secure/vehicle-document-types', 'page-center');
  }

  getCountries(): Observable<any> {
    return this.restApiService.get('', '/secure/countries', 'page-center');
  }

  getState(id): Observable<any> {
    return this.restApiService.get('', `/secure/countries/${id}/states`, 'page-center');
  }

  getCitybyCountries(code): Observable<any> {
    return this.restApiService.get('', '/secure/countries/' + code + '/citiesbycountry', 'page-center');
  }

  getCitybyState(code): Observable<any> {
    return this.restApiService.get('', '/secure/countries/' + code + '/citiesbystate', 'page-center');
  }

  getCitybyZipCode(zipCode, countryCode?: any): Observable<any> {
    return this.restApiService.get('', '/secure/countries-city?zipCode=' + zipCode + '&countryCode=' + countryCode, 'page-center');
  }

  getDispatcher(data, code): Observable<any> {
    return this.restApiService.get('', `/secure/dispatchers/data/search?searchText=${data}&roleCode=${code}`, 'page-center');
  }

  commentReply(data, id): Observable<any> {
    return this.restApiService.post('', `/secure/booking/trip/${id}/comment-reply`, data, 'page-center');
  }

  searchPassengers(url: string): Observable<any> {
    return this.restApiService.get('', url, 'page-center');
  }

  readNotifications(notificationId, readStatus): Observable<any> {
    return this.restApiService.put('', `/secure/notification/${notificationId}/status`, readStatus, 'page-center');
  }

  addCorporateBooking(data, serviceType): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${serviceType}/corporate`, data, 'page-center');
  }

  addHotelBooking(data, serviceType, hotelId): Observable<any> {
    return this.restApiService.post('', `/secure/hotels/${hotelId}/booking/${serviceType}`, data, 'page-center');
  }

  getLabel(string) {
    let select;
    this.translateService.get(string).subscribe(translatedValue => {
      select = translatedValue;
    });
    return select;
  }

  getTiming() {
    return this.restApiService.get('', '/secure/settings/timeouts', 'page-center');
  }

  packageBind(serviceType, passengerId?, vehicleTypeId?): Observable<any> {
    if (vehicleTypeId !== null && vehicleTypeId !== undefined) {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' +
        serviceType + '?vehicleTypeId=' + vehicleTypeId + '&passengerId=' + passengerId;
      return this.restApiService.get('', url, 'page-center');
    } else {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' + serviceType;
      return this.restApiService.get('', url, 'page-center');
    }
  }

  getPricingDetails(data) {
    return this.restApiService.get('', '/secure/packages/' + data, 'page-center');
  }

  setPackages(data, id?, corporateId?): Observable<any> {
    return this.restApiService.put('', `/secure/packages/${id}/partners/${corporateId}`, data, 'page-center');
  }

  getRentalPricingDetails(params) {
    return this.restApiService.get('', '/secure/packages/rental/partners/' + params, 'page-center');
  }

  updateRentalPackage(data, corporateId) {
    return this.restApiService.post('', `/secure/packages/rental/partners/${corporateId}`, data, 'page-center');
  }

  addPricingPackages(data, id?: null) {
    if (id !== undefined && id !== null && id !== '') {
      return this.restApiService.put('', '/secure/packages/' + id + '/chauffeur', data, 'page-center');
    } else {
      return this.restApiService.post('', '/secure/packages', data, 'page-center');
    }
  }

  updateCorporatePricingPackages(data, id: null, corporateId) {
    return this.restApiService.put('', '/secure/packages/' + id + '/partners/' + corporateId, data, 'page-center');
  }

  setRoute(data, corporateId?): Observable<any> {
    return this.restApiService.put('', `/secure/packages/routes/destinations/rates/partners/${corporateId}`, data, 'page-center');
  }

  getRoutingDetails(routeId, partnerId) {
    return this.restApiService.get('', `/secure/packages/routes/${routeId}/partners/${partnerId}`, 'page-center');
  }

  searchDriver(url: string): Observable<any> {
    return this.restApiService.get('', url, 'page-center');
  }

  addUpdateBooking(data, serviceType, id?): Observable<any> {
    return this.restApiService.post('', `/secure/booking/${serviceType}/taxi`, data, 'page-center');
  }

  getHotels(): Observable<any> {
    return this.restApiService.get('', `/secure/hotels`, 'page-center');
  }

  assignDriver(bookingId, data): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + bookingId + '/assign-driver', data);
  }

  resendCode(bookingId, passengerId): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + bookingId + '/verification-code');
  }

  cancelBooking(id, data): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + id + '/cancel', data);
  }

  bookingDetails(id): Observable<any> {
    return this.restApiService.get('', '/secure/booking/' + id, 'page-center');
  }

  startTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripid + '/start-trip', data);
  }

  securityCheck(data, tripid): Observable<any> {
    return this.restApiService.post('', '/secure/bookings/' + tripid + '/security-check', data);
  }

  endTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripid + '/end-trip', data);
  }

  rentalEndTrip(data, tripid): Observable<any> {
    return this.restApiService.put('', '/secure/bookings/' + tripid + '/end-rental-booking', data);
  }

  extendPackage(tripid): Observable<any> {
    return this.restApiService.put('', '/secure/bookings/' + tripid + '/extend-package');
  }

  getDriverCurrentLocation(driverId): Observable<any> {
    return this.restApiService.get('', '/secure/drivers/' + driverId + '/location', 'page-center');
  }

  hasAccessPresent(accessCode) {
    const menuList = this.getSelectedMenuAccessesList();
    const hasAccess = menuList.find((elm) => {
      return elm.accessCode === accessCode;
    });

    if (hasAccess !== undefined && hasAccess !== null) {
      return true;
    }
    return false;
  }

  getSubmenuListByMenuName(menuName) {
    let menuItems: any = [];
    menuItems = this.storageService.getItem(AppSettings.ACCESS_MENU);
    for (let menuIndex = 0; menuIndex < menuItems.length; menuIndex++) {
      const menu = menuItems[menuIndex];
      if (menu.name === menuName) {
        return menu.subMenuList;
      }
    }
    return [];
  }

  getSelectedMenuAccessesList(returnObj?) {
    const location = window.location.href;
    const urlArray = location.split('#');
    const displayUrls = urlArray[urlArray.length - 1];
    const displayUrl = displayUrls.split('?')[0];
    let menuItems: any = [];
    menuItems = this.storageService.getItem(AppSettings.ACCESS_MENU);
    for (let menuIndex = 0; menuIndex < menuItems.length; menuIndex++) {
      const menu = menuItems[menuIndex];
      if (displayUrl.indexOf(menu.displayUrl) >= 0) {
        if (returnObj) {
          return menu;
        }
        return menu.accesses;
      } else {
        if (menu.subMenuList && menu.subMenuList.length) {
          const subMenuAccess = this.checkSubmenus(menu.subMenuList, displayUrl, returnObj);
          if (subMenuAccess !== undefined && subMenuAccess !== null) {
            return subMenuAccess;
          }
        }
      }
    }
    return [];
  }

  checkSubmenus(subMenuList, menuName, returnObj?) {
    if (subMenuList !== undefined && subMenuList !== null) {
      for (let menuIndex = 0; menuIndex < subMenuList.length; menuIndex++) {
        const menu = subMenuList[menuIndex];
        if (menuName.indexOf(menu.displayUrl) >= 0) {
          if (returnObj) {
            return menu;
          }
          return menu.accesses;
        } else {
          if (menu.subMenuList && menu.subMenuList.length) {
            const subMenuAccess = this.checkSubmenus(menu.subMenuList, menuName, returnObj);
            if (subMenuAccess !== undefined && subMenuAccess !== null) {
              return subMenuAccess;
            }
          }
        }
      }
      return null;
    }
  }

  checkAndSetSelectedMenu(routeUrl) {
    if (!this.accesses) {
      this.accesses = this.storageService.getItem(AppSettings.ACCESS_MENU);
    }
    for (let i = 0; i < this.accesses.length; i++) {
      if (this.compareUrl(this.accesses[i].displayUrl, routeUrl)) {
        this.storageService.setItem('selectedMenu', this.accesses[i].accessGroupCode);
        return true;
      } else {
        if (this.accesses[i].subMenuList.length) {
          for (let j = 0; j < this.accesses[i].subMenuList.length; j++) {
            if (this.compareUrl(this.accesses[i].subMenuList[j].displayUrl, routeUrl)) {
              this.storageService.setItem('selectedMenu', this.accesses[i].subMenuList[j].accessGroupCode);
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  compareUrl(srcUrl, routeUrl) {
    return srcUrl ? srcUrl.toLowerCase().indexOf(routeUrl.toLowerCase()) >= 0 : false;
  }

  getCities(): Observable<any> {
    return this.restApiService.get('', '/secure/service-area/cities', 'page-center');
  }

  fetchTourBooking(tourId): Observable<any> {
    return this.restApiService.get('', '/secure/tour/' + tourId, 'page-center');
  }

  getDropDownData(list, key, value) {
    return (list !== null && list !== undefined) ?
      list.map(item => ({ label: item[key], value: item[value] })) : [];
  }

  getDrivers(vehicleTypeId, tourId?, search?): Observable<any> {
    const searchData = search ? search : '';
    return this.restApiService.get('', `/secure/drivers/assign-trip/list/` + vehicleTypeId + '?tourId=' +
      tourId + '&searchText=' + searchData + '&limit=10&offset=0', 'page-center');
  }

  driverAssigned(tourId, driverId): Observable<any> {
    return this.restApiService.put('', '/secure/tour/assign-driver/' + tourId + '/' + driverId, 'page-center');
  }

  driverOntheWay(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/tour/driver/ontheway/' + tourId, 'page-center');
  }

  driverArrived(tripId): Observable<any> {
    return this.restApiService.put('', '/secure/booking/' + tripId + '/arrived', 'page-center');
  }

  OnTrip(tripId, data): Observable<any> {
    return this.restApiService.put('', '/secure/booking/trip/' + tripId + '/start-trip', data, 'page-center');
  }

  dropTrip(data): Observable<any> {
    return this.restApiService.post('', '/secure/tour/drop', data, 'page-center');
  }

  rideShareDriverOntheWay(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/ride-share/' + tourId + '/driver/on-the-way', 'page-center');
  }

  rideShareDriverArrived(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/ride-share/' + tourId + '/driver/arrived', 'page-center');
  }

  rideShareOnTrip(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/ride-share/' + tourId + '/start-trip', 'page-center');
  }

  rideShareDropTrip(tourId, data): Observable<any> {
    return this.restApiService.post('', '/secure/ride-share/' + tourId + '/end-trip', data, 'page-center');
  }

  acknowledgeTrip(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/tour/' + tourId + '/acknowledge', 'page-center');
  }

  acknowledgeRideShareTrip(tourId): Observable<any> {
    return this.restApiService.put('', '/secure/ride-share/' + tourId + '/acknowledge', 'page-center');
  }

  doPayment(data): Observable<any> {
    return this.restApiService.post('', '/secure/manual-booking/stripe', data, 'page-center');
  }

  getCardDetails(userId): Observable<any> {
    return this.restApiService.get('', `/secure/manual-booking/web-stripe/` + userId, 'page-center');
  }

  getDateFormat() {
    let dateFormatRecord = this.getTenantConfigValue(AppSettings.DATE_FORMAT);
    dateFormatRecord = dateFormatRecord === null ? '' : dateFormatRecord.toLowerCase();
    const check = dateFormatRecord.includes('yyyy');
    if (check) {
      dateFormatRecord = dateFormatRecord.replace('yyyy', 'yy');
    }
    return dateFormatRecord;
  }

  cashCollecte(tripid, data): Observable<any> {
    return this.restApiService.put('', '/secure/tour/collect-cash/' + tripid, data);
  }

  converDistanceWithTime(distance, time) {
    return (distance / 1000) + ' km - ' + this.convertMinsToHrs(time);
  }

  convertMinsToHrs(time) {
    return (Math.floor(time / 60)) + ' hrs ' + (time % 60) + ' mins';
  }

  timeConvertion(milisec: any) {
    const duration = parseInt(milisec, 10);
    const seconds = Math.ceil((duration / 1000) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    let time;
    if (hours > 0) {
      time = hours + 'h ' + minutes + 'm ' + seconds + 's';
    } else if (minutes > 0) {
      time = minutes + 'm ' + seconds + 's';
    } else if (seconds > 0) {
      time = seconds + 's';
    }
    return time;
  }

  setFullName(firstName, lastName?) {
    let fullName;
    if (lastName) {
      fullName = firstName + ' ' + lastName;
    } else {
      fullName = firstName;
    }
    return fullName;
  }

  setFullPhoneNumber(countryCode, phoneNumber) {
    let fullNumber;
    if (countryCode) {
      fullNumber = countryCode + ' ' + phoneNumber;
    } else {
      fullNumber = phoneNumber;
    }
    return fullNumber;
  }

  setFullAddress(getAddress1, getAddress2, getCity?, getZipcode?) {
    let address1 = '';
    let address2 = '';
    let city = '';
    let zipcode = '';
    if (getAddress1 !== undefined && getAddress1 !== null) {
      address1 = getAddress1;
    }
    if (getAddress2 !== undefined && getAddress2 !== null) {
      address2 = ', ' + getAddress2;
    }
    if (getCity !== undefined && getCity !== null) {
      city = ', ' + getCity;
    }
    if (getZipcode !== undefined && getZipcode !== null) {
      zipcode = ', ' + getZipcode;
    }
    const fullAddress = address1 + address2 + city + zipcode;
    return fullAddress;
  }



  decodePolyline(encoded: string) {
    // return google.maps.geometry.encoding.decodePath(encoded);
    let index = 0;
    const len = encoded.length;
    const path = [];
    let lat = 0;
    let lng = 0;
  
    while (index < len) {
      let b: number;
      let shift = 0;
      let result = 0;
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      const dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
      lat += dlat;
  
      shift = 0;
      result = 0;
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      const dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));
      lng += dlng;
  
      const point = {
        lat: lat / 1e5,
        lng: lng / 1e5,
      };
      path.push(point);
    }
    return path;
  }

}
