<div class="email-verification track-box-container">
  <div class="signin-box-inner-container">
      <div class="col-12">
        <div class="col-12">
          <img src="./assets/images/temp/logo-nemp.png" alt="CHG" style="height: 50px;">
        </div>
        <hr>
        <div class="col-12" style="width:80vw; margin: 0 auto;">
          <div class="col-12" *ngIf="!isRedirected">
            <label class="main-heading" translate>label_close_your_chg_account</label>
            <p>Closing your account means permanently deleting your profile and removing access to all your CHG information from our app.</p>
            <strong>To close your CHG account from the app</strong>
            <ol class="pl-2">
              <li>Tap more <img src="./assets/images/temp/tab-selected.svg" style="width: 14px; margin: -2px 3px;" alt=""> option from the bottom menu <strong>> Settings.</strong></li>
              <li>Tap <strong>Delete Account</strong> under the settings menu.</li>
              <li>Read all the information about closing account & Tap <strong>Delete My Account </strong>to proceed with closing your account.</li>
              <li>We will send the <strong>One Time Password</strong> to your registered Email/Phone Number.</li>
              <li>Enter <strong>One Time Password</strong> and give the confirmation to close your account.</li>
              <li>The account will be deactivated immediately and permanently deleted after 30 days this will be permanently delete your</li>
            </ol>
            <div>
              <p>
                <label class="text-bold">Personal Info & Profile Photo</label>
              </p> 
              <p>
                <label class="text-bold">Trip History</label>
              </p>
              <p>
                <label class="text-bold">Reminders & Notifications</label>
              </p>
              <p>
                <label class="text-bold">Entire Account</label>
              </p>
            </div>
              
            <div class="">
              <div class="grid box-bg">
                <div class="col">
                  <strong>Quickly close your account</strong>
                  <p>
                    We'll be sorry to see you go. Closing your account will delete all the personal information,
                     trip history and all notifications
                  </p>
                </div>
                <div class="col-fixed">
                  <button [label]="'label_delete_account' | translate" class="plus-button ml-2" (click)="redirectToDeleteAccount();" pButton type="button"></button>   
                </div>
              </div>
              
            </div>   
                
                
                
          
          </div>

          <div class="col-12" *ngIf="isRedirected">
          <label class="main-heading" translate>label_delete_account</label>
          <p translate>label_delete_account_info</p>
          <ul class="pl-2">
            <li translate>label_personal_info_and_profile_photo</li>
            <li translate>label_trip_history</li>
            <li translate>Reminders & Notifications</li>
            <li translate>label_entire_account</li>
          </ul>

          <div class="form-container">
            <form class="col-12 ml-minus-21" [formGroup]="deleteForm">

              <div class="form-field col-sm-12 md:col-4 lg:col-4 pt-0">


                <div class="form-label">
                  <label for="username" translate>label_phone_or_email</label>
                  <label class="asterisk">*</label>
                </div>
                <div class="grid">
                  <div class="col-fixed p-inputgroup mobile-number" [style]="{'width':'90px'}" [ngClass]="isNumber? '': 'hidden'">
                    <span class="p-inputgroup-addon">
                      <i class="fa fa-plus"></i>
                    </span>
                    <input class="input-mobile-code" type="text" pInputText formControlName="numCountryCode" placeholder="Code" />
                  </div>
                  <div class="form-control col">
                    <input type="text" pInputText formControlName="phoneOrEmail" (keyup)="checkInputValue()" (keydown.space)="$event.preventDefault()" [placeholder]="'label_phone_or_email' | translate" />
                  </div>
                </div>
               
                <div class="form-errors"
                *ngIf="deleteForm.controls.phoneOrEmail.errors && (deleteForm.controls.phoneOrEmail.dirty || deleteForm.controls.phoneOrEmail.touched)">
                <div *ngIf="deleteForm.controls.phoneOrEmail.errors.required" translate>
                  err_required_email
                </div>
                <div *ngIf="deleteForm.controls.phoneOrEmail.errors.pattern" translate>
                  err_invalid_email_or_phone
                </div>
              </div>
    
               </div>
               <div class="form-field col-sm-12 md:col-4 lg:col-4 pt-0" *ngIf="isSendOTP">
                <div class="form-label">
                  <label for="username" translate>label_enter_otp</label>
                  <label class="asterisk">*</label>
                </div>
                <div class="form-control">
                  <input type="text" pInputText formControlName="otp" [placeholder]="'label_enter_otp' | translate"/>
                </div>
                <div>One time password has been sent to <span class="send-otp">{{isNumber? '+'+deleteForm.controls.numCountryCode.value : ''}} {{deleteForm.controls.phoneOrEmail.value}}</span></div>
               </div>
               <div class="col-12">
                <span class="timer-text" *ngIf="isSendOTP && timeLeft !== 0">Resend OTP in {{timeLeft | secondsToMin}} seconds.</span>
                <button label="Send OTP" *ngIf="!isSendOTP" [disabled]="!deleteForm.controls.phoneOrEmail.valid" class="plus-button" (click)="sendOTP();" pButton type="button"></button>
                <!--<button label="Resend OTP" *ngIf="isSendOTP && timeLeft === 0" [disabled]="!deleteForm.controls.phoneOrEmail.valid" class="plus-button" (click)="sendOTP();" pButton type="button"></button> -->
                <span  *ngIf="isSendOTP && timeLeft === 0" class="resend-otp" (click)="sendOTP();">Resend OTP</span>
               </div>
            </form>
            </div>
            <div class="col-12 align-right">
              <button [label]="'label_cancel' | translate" class="negative-button plus-button" (click)="cancel();" pButton type="button"></button>
               <button [label]="'label_delete_my_account' | translate" [disabled]="!deleteForm.valid" class="plus-button ml-2" (click)="deleteMyAccount();" pButton type="button"></button>   
             </div>
        </div>
      </div>
      </div>
  </div>
</div>

<p-dialog modal="true" position="center" responsive="true" ng-style="overflow: auto"
  styleClass="add-routes-popup estimation" [(visible)]="isConfirmPopDisplay"
  [contentStyle]="{'overflow':'auto'}" [style]="{width: '500px'}">
  <p-header>Confirmation</p-header>
  <div>
    <div class="col-12">
      <p translate>label_send_one_time_password</p>
    </div>
    <div class="col-12 form-field">
      <input type="text" class="border-rounded" pInputText [(ngModel)]="confirmOTP" [placeholder]="'label_enter_otp' | translate" />
    </div>
    <div class="col-12">
      <span  *ngIf="isSendOTP && confirmTimeLeft === 0" class="resend-otp" (click)="sendOTPForDeleteConfirmation();">Resend OTP</span>
      <span class="timer-text" *ngIf="isSendOTP && confirmTimeLeft !== 0">Resend OTP in {{confirmTimeLeft | secondsToMin}} seconds.</span>
    </div>
    
    <div class="col-12">
      <div class="ok-button" style="text-align:right;">
        <button label="Delete permanently" class="plus-button mr-2" [disabled]="!confirmOTP"
          (click)="deleteAccountPermanently();" pButton type="button"></button>
        <button label="cancel" class="negative-button plus-button"
          (click)="cancelFromDialog();" pButton type="button"></button>
      </div>
    </div>
  </div>
</p-dialog>
<p-toast key="commonToast"></p-toast>